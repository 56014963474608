import { toast } from "react-toastify";
import {
  createDSApi,
  createHHApi,
  createSVApi,
  deleteTMLAnalysisDeleteApi,
  getAssignemtnLabDropdownApi,
  getDSApi,
  getHHApi,
  getLabWiseSetAssignmentData,
  getLastStepAssignmentData,
  getlotnodropdownApi,
  getScopeworkUpdateApi,
  getSVApi,
  jobinstructionUpdateApi,
  opsSizeAnalysisCreateApi,
  opsSizeAnalysisGetApi,
  opsSizeAnalysisUpdateApi,
  TMLAnalysisDataCreateApi,
  TMLAnalysisDataListApi,
  TMLDataCreateApi,
  TMLDataDeleteApi,
  TMLDatagetAllApi,
  TMLDataUpdateApi,
  updateDSApi,
  updateHHApi,
  updateSVApi,
} from "../../../../services/api";
import {
  deleteDataFromApi,
  GetTenantDetails,
  postDataFromApi,
  putDataFromApi,
} from "../../../../services/commonServices";
import { encryptDataForURL } from "../../../../utills/useCryptoUtils";

export const handleTMLOperationCreateUpdate = async (
  actionSelected,
  editableIndex,
  tableData,
  formData,
  section,
  setSaveClicked,
  setEditableIndex,
  setPopupIndex,
  popupIndex,
  setPopupOpenAssignment,
  setIsBtnClicked,
  setIsOverlayLoader,
  setTableData,
  setFormData,
  OperationType,
  OperationTypeID,
  getAllSampleMarkList,
  setIsDisplayNewAddOption
) => {
  if (actionSelected === "Save" || actionSelected === "customSave") {
    setIsBtnClicked(true);
    let newRowIndex = editableIndex;
    if (actionSelected === "customSave") {
      newRowIndex = tableData.length;
    }
    let extraJsonData = {};
    if (OperationType != "Transportable moisture limit (TML)") {
      // extraJsonData["jism_composite_lot"] =
      //   formData["1"]?.["jism_composite_lot_" + newRowIndex];
      // if (OperationType === "Pre-Shipment (PSI)") {
      //   extraJsonData["jism_lot_no"] =
      //     formData["1"]?.["jism_lot_no_" + newRowIndex];
      // }
    }
    let quantityCount = 0;
    tableData.map((singleData, indextab) => {
      if (indextab === newRowIndex) {
        quantityCount =
          quantityCount +
          parseInt(formData["1"]?.["jism_quantity_" + newRowIndex]);
      } else {
        quantityCount = quantityCount + parseInt(singleData.jism_quantity);
      }
    });
    if (quantityCount > formData[0].ji_totalqty) {
      toast.error("You can't add quantity more then total quantity", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsBtnClicked(false);
      return;
    }
    let payload = {
      jism_lots: {
        fk_jiid: formData[0].ji_id,
        fk_jisid: OperationTypeID,
        jism_jimode: "Vessel",
        jism_jisubmode: OperationType,
        jism_sample_qty: formData["1"]?.["jism_sample_qty_" + newRowIndex],
        jism_sealnumber: formData["1"]?.["jism_sealnumber_" + newRowIndex],
        jism_marknumber: formData["1"]?.["jism_marknumber_" + newRowIndex],
        jism_quantity: formData["1"]?.["jism_quantity_" + newRowIndex],
        jism_sampleweight: formData["1"]?.["jism_sampleweight_" + newRowIndex],
        jism_sampletype: formData["1"]?.["jism_sampletype_" + newRowIndex],
        jism_sampleunit: formData["1"]?.["jism_sampleunit_" + newRowIndex],
        jism_is_composite:
          formData["1"]?.["jism_is_composite_" + newRowIndex] === "Composite"
            ? true
            : false,
        jism_jsonb_front: extraJsonData,
      },
    };
    if (formData["1"]?.["jism_is_composite_" + newRowIndex] === "Composite") {
      payload.jism_lots.jism_composite_lot_nos =
        formData["1"]?.["jism_composite_lot_nos_" + newRowIndex];
    } else {
      payload.jism_lots.jism_lot_no =
        formData["1"]?.["jism_lot_no_" + newRowIndex];
    }
    let nonRequiredFields = [
      "jism_jsonb_front",
      "jism_composite_lot_nos",
      "jism_lot_no",
    ];

    for (let obj in payload.jism_lots) {
      if (
        (payload["jism_lots"][obj] === undefined ||
          payload["jism_lots"][obj] === "") &&
        !nonRequiredFields.includes(obj)
      ) {
        const field = section.rows[0].filter((field, index) => {
          if (field.name === obj) {
            field.label = section.headers[index].label;
            return true;
          }
          return false;
        });

        if (field.length > 0) {
          let errLabel = field ? field[0].label : "";
          toast.error(errLabel + " is required", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setIsBtnClicked(false);
          return;
        }
      }
    }

    let res;
    if (actionSelected !== "customSave") {
      let newMainPayload = {};
      newMainPayload.jism_id = tableData[editableIndex].jism_id;
      newMainPayload.ji_samplemark_data = payload.jism_lots;
      res = await putDataFromApi(TMLDataUpdateApi, newMainPayload);
    } else {
      let newMainPayload = {};
      newMainPayload.ji_samplemark_data = payload.jism_lots;
      res = await postDataFromApi(TMLDataCreateApi, newMainPayload);
    }
    if (res.data.status === 200) {
      getAllSampleMarkList(
        formData[0]?.ji_id,
        OperationTypeID,
        setTableData,
        formData,
        setFormData,
        section,
        null,
        null,
        null,
        setIsDisplayNewAddOption
      );
      setPopupOpenAssignment(false);
      setPopupIndex("");
      setEditableIndex("");
      setIsBtnClicked(false);
      setIsOverlayLoader(false);
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      setIsBtnClicked(false);
      setIsOverlayLoader(false);
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setSaveClicked(false);
    setIsOverlayLoader(false);
  } else if (actionSelected === "Delete") {
    setSaveClicked(true);
    let payload = {
      jism_id: tableData[popupIndex]?.jism_id,
    };
    setIsOverlayLoader(true);
    let res = await deleteDataFromApi(TMLDataDeleteApi, payload);
    if (res.data.status === 200) {
      getAllSampleMarkList(
        formData[0]?.ji_id,
        OperationTypeID,
        setTableData,
        formData,
        setFormData,
        section,
        null,
        null,
        null,
        setIsDisplayNewAddOption
      );
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsOverlayLoader(false);
    setSaveClicked(false);
  } else if (actionSelected === "Cancel") {
    setEditableIndex("");
  }
};

export const OperationCreateDataFunction = async (
  formData,
  setIsOverlayLoader,
  setIsPopupOpen,
  OperationType,
  OperationTypeID,
  navigate,
  status,
  isNavigate,
  JRFData,
  operationStepNo
) => {
  console.log(OperationType, operationStepNo);
  let payloadData = {
    status: status,
  };

  let res;
  setIsOverlayLoader(true);
  let MainData = {
    ji_scopeofwork: payloadData,
    jis_id: OperationTypeID,
  };
  if (JRFData && JRFData.length > 0) {
    MainData["for_jrf"] = {
      jila_id: JRFData,
    };
  }
  res = await putDataFromApi(getScopeworkUpdateApi, MainData);
  if (res?.data?.status === 200) {
    toast.success(res?.data?.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setIsOverlayLoader(false);
    setIsPopupOpen(false);
    if (isNavigate) {
      if (operationStepNo == 1) {
        const currentURL = window.location.href;
        const newQueryString =
          "?OperationType=" +
          encryptDataForURL(OperationType) +
          "&operationId=" +
          encryptDataForURL(OperationTypeID) +
          "&operationStepNo=" +
          encryptDataForURL(2);
        const spbaseUrl = currentURL.split("?");
        let baseUrl = spbaseUrl[0];
        if (spbaseUrl.length > 2) {
          baseUrl = spbaseUrl[0] + spbaseUrl[1];
        }
        const newURL = baseUrl + newQueryString;
        window.location.href = newURL;
        setTimeout(() => {
          window.location.reload();
        }, 10);
        return;
      } else if (operationStepNo == 3) {
        if (
          ["Transportable moisture limit (TML)", "Pre-Shipment (PSI)"].includes(
            OperationType
          )
        ) {
          const currentURL = window.location.href;
          const newQueryString =
            "?OperationType=" +
            encryptDataForURL(OperationType) +
            "&operationId=" +
            encryptDataForURL(OperationTypeID) +
            "&operationStepNo=" +
            encryptDataForURL(5);
          const spbaseUrl = currentURL.split("?");
          let baseUrl = spbaseUrl[0];
          if (spbaseUrl.length > 2) {
            baseUrl = spbaseUrl[0] + spbaseUrl[1];
          }
          const newURL = baseUrl + newQueryString;
          window.location.href = newURL;
          window.location.reload();
          return;
          // navigate(
          //   `/operation/vessel-ji-list/vessel-list/${encryptDataForURL(
          //     formData[0].ji_id
          //   )}/${encryptDataForURL("analysis")}/${encryptDataForURL(
          //     OperationTypeID
          //   )}?OperationType=${encryptDataForURL(
          //     OperationType
          //   )}&operationId=${encryptDataForURL(
          //     OperationTypeID
          //   )}&operationStepNo=${encryptDataForURL(5)}`
          // );
        } else {
          navigate(
            "/operation/vessel-ji-list/vessel-list/" +
              encryptDataForURL(formData[0]?.["ji_id"]) +
              "/" +
              encryptDataForURL(OperationType) +
              "?OperationType=" +
              encryptDataForURL(OperationType) +
              "&operationId=" +
              encryptDataForURL(OperationTypeID) +
              "&operationStepNo=" +
              encryptDataForURL(4)
          );
        }
      } else if (operationStepNo == 4) {
        navigate(
          `/operation/vessel-ji-list/vessel-list/${encryptDataForURL(
            formData[0].ji_id
          )}/${encryptDataForURL("analysis")}/${encryptDataForURL(
            OperationTypeID
          )}?OperationType=${encryptDataForURL(
            OperationType
          )}&operationId=${encryptDataForURL(
            OperationTypeID
          )}&operationStepNo=${encryptDataForURL(5)}`
        );
        return;
      } else if (operationStepNo == 5) {
        return;
      } else {
        navigate(
          `/operation/vessel-ji-list/vessel-list/${encryptDataForURL(
            formData[0].ji_id
          )}/${encryptDataForURL("analysis")}/${encryptDataForURL(
            OperationTypeID
          )}?OperationType=${encryptDataForURL(
            OperationType
          )}&operationId=${encryptDataForURL(
            OperationTypeID
          )}&operationStepNo=${encryptDataForURL(3)}`
        );
        return;
      }
    } else {
      setIsPopupOpen(false);
      setIsOverlayLoader(false);
      return true;
    }
  } else {
    toast.error(res.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  setIsPopupOpen(false);
  setIsOverlayLoader(false);
};

export const vesselListBackFunctionality = (
  formData,
  OperationType,
  OperationTypeID,
  navigate,
  operationStepNo
) => {
  if (operationStepNo == 1) {
    navigate(
      "/operation/vessel-ji-list/vessel-list/" +
        encryptDataForURL(formData[0]?.["ji_id"]) +
        "?action=" +
        encryptDataForURL("View") +
        "&status=" +
        encryptDataForURL("accepted")
    );
  } else if (operationStepNo == 2) {
    navigate(
      "/operation/vessel-ji-list/vessel-list/" +
        encryptDataForURL(formData[0]?.["ji_id"]) +
        "/" +
        encryptDataForURL(OperationType) +
        "?OperationType=" +
        encryptDataForURL(OperationType) +
        "&operationId=" +
        encryptDataForURL(OperationTypeID) +
        "&operationStepNo=" +
        encryptDataForURL(1)
    );
    setTimeout(() => {
      window.location.reload();
    }, 10);
    return;
  } else if (operationStepNo == 3) {
    navigate(
      "/operation/vessel-ji-list/vessel-list/" +
        encryptDataForURL(formData[0]?.["ji_id"]) +
        "/" +
        encryptDataForURL(OperationType) +
        "?OperationType=" +
        encryptDataForURL(OperationType) +
        "&operationId=" +
        encryptDataForURL(OperationTypeID) +
        "&operationStepNo=" +
        encryptDataForURL(2)
    );
    return;
  } else if (operationStepNo == 4) {
    navigate(
      `/operation/vessel-ji-list/vessel-list/${encryptDataForURL(
        formData[0].ji_id
      )}/${encryptDataForURL("analysis")}/${encryptDataForURL(
        OperationTypeID
      )}?OperationType=${encryptDataForURL(
        OperationType
      )}&operationId=${encryptDataForURL(
        OperationTypeID
      )}&operationStepNo=${encryptDataForURL(3)}`
    );
    return;
  } else if (operationStepNo == 5) {
    if (
      ["Transportable moisture limit (TML)", "Pre-Shipment (PSI)"].includes(
        OperationType
      )
    ) {
      const currentURL = window.location.href;
      const newQueryString =
        "?OperationType=" +
        encryptDataForURL(OperationType) +
        "&operationId=" +
        encryptDataForURL(OperationTypeID) +
        "&operationStepNo=" +
        encryptDataForURL(3);
      const spbaseUrl = currentURL.split("?");
      let baseUrl = spbaseUrl[0];
      if (spbaseUrl.length > 2) {
        baseUrl = spbaseUrl[0] + spbaseUrl[1];
      }
      const newURL = baseUrl + newQueryString;
      window.location.href = newURL;
      window.location.reload();
      return;
    } else {
      navigate(
        "/operation/vessel-ji-list/vessel-list/" +
          encryptDataForURL(formData[0]?.["ji_id"]) +
          "/" +
          encryptDataForURL(OperationType) +
          "?OperationType=" +
          encryptDataForURL(OperationType) +
          "&operationId=" +
          encryptDataForURL(OperationTypeID) +
          "&operationStepNo=" +
          encryptDataForURL(4)
      );
    }
    return;
  }
};

export const createTMLAnalysisDetails = async (
  parameterData,
  setIsLoading,
  setIsOverlayLoader,
  setParameterDataTable,
  setCustomFormData,
  setIsSubmit,
  formData,
  initialCustomData,
  OperationTypeID
) => {
  let smpl_set_smpljson = [];
  let smpl_set_paramjson = [];
  let smpl_set_groupjson = [];
  let param_sequance_no = 0;
  parameterData.map((paramData, seqIndex) => {
    parameterData[parameterData.length - 1].smpl_set_smpljson.map((sample) => {
      if (!smpl_set_smpljson.includes(sample)) {
        smpl_set_smpljson.push(sample);
      }
    });
    let basis = [];
    let basiscodes = [];
    if (paramData.smpl_set_basisjson_name.length > 0) {
      basiscodes = paramData.smpl_set_basisjson_name.split(",");
    } else {
      basiscodes = [];
    }
    paramData.smpl_set_basisjson.map((basId, i) => {
      basis.push({
        basis_id: basId,
        basis_code: basiscodes[i],
      });
    });
    if (paramData["is_group_param"] == "Group") {
      let parameters = [];
      const groupParamJson = paramData.groupJsonParameter.filter(
        (singleparam) => {
          singleparam.param_sequence = param_sequance_no;
          param_sequance_no++;
          return true;
        }
      );
      smpl_set_groupjson.push({
        group_id: paramData.smpl_set_groupjson,
        group_name: paramData.smpl_set_groupjson_name,
        parameters: groupParamJson,
        sequanceNo: seqIndex,
        is_set_for_JRF: parameterData[parameterData.length - 1].is_set_for_JRF,
      });
    } else {
      smpl_set_paramjson.push({
        param_id: paramData.smpl_set_paramjson,
        param_name: paramData.smpl_set_paramjson_name,
        std_id: paramData.smpl_set_testmethodjson,
        std_name: paramData.smpl_set_testmethodjson_name,
        basis: basis,
        sequanceNo: seqIndex,
        param_unit: paramData.smpl_set_unit,
        is_set_for_JRF: parameterData[parameterData.length - 1].is_set_for_JRF,
        param_sequence: param_sequance_no,
      });
      param_sequance_no++;
    }
  });
  const newMainPayload = {
    ji_lms_assignment: {
      fk_jiid: formData[0].ji_id,
      fk_jisid: OperationTypeID,
      jism_jimode: "Vessel",
      fk_labid: formData[1]?.["smpl_filter_lab"],
      jila_for_jrf: false,
      jila_for_tpi: false,
      // jila_for_jrf: parameterData[parameterData.length - 1].is_set_for_JRF === "Yes" ? true : false,
      // jila_for_tpi: parameterData[parameterData.length - 1].is_set_for_JRF === "Yes" ? false : true,
      jila_set_markjson: smpl_set_smpljson,
      jila_set_groupjson: smpl_set_groupjson,
      jila_set_paramjson: smpl_set_paramjson,
      tenant: GetTenantDetails(1),
    },
  };
  setIsLoading(true);
  setIsOverlayLoader(true);
  const res = await postDataFromApi(TMLAnalysisDataCreateApi, newMainPayload);
  if (res.data.status === 200) {
    setParameterDataTable([]);
    setCustomFormData(initialCustomData);
    setIsSubmit(false);
    setIsOverlayLoader(false);
    toast.success(res.data.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } else {
    toast.error(res.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  setIsOverlayLoader(false);
  setIsLoading(false);
};

export const getAllSampleMarkList = async (
  ji_id,
  OperationTypeID,
  setTableData,
  formData,
  setFormData,
  section,
  useFor = "",
  existingData,
  updatedFormData,
  setIsDisplayNewAddOption
) => {
  try {
    const bodyData = {
      ji_id: ji_id,
      jis_id: OperationTypeID,
    };
    if (
      ["sampleInward", "jrf", "inwardChecklist", "GroupAssignment"].includes(
        useFor
      )
    ) {
      bodyData.jrf_id = formData[0]?.jrf_id;
    }
    let res = await postDataFromApi(TMLDatagetAllApi, bodyData);
    if (res?.data?.status === 200 && res.data.data) {
      const responseData = res.data.data;
      if (!updatedFormData) {
        updatedFormData = { ...formData };
      }
      let i = 0;
      let quantityCount = 0;
      const actualResponseData = responseData.filter((singleInwardData) => {
        quantityCount =
          quantityCount + parseInt(singleInwardData.jism_quantity);
        if (
          [
            "sampleInward",
            "jrf",
            "inwardChecklist",
            "GroupAssignment",
          ].includes(useFor)
        ) {
          if (!singleInwardData.jism_is_jrf) {
            return false;
          }
        }
        if (useFor == "sampleInward") {
          const filterData = existingData.filter((existingData) => {
            return (
              singleInwardData["jism_id"] == existingData.fk_ji_sample_marks_id
            );
          });
          if (filterData.length) {
            i++;
            return true;
          }
        }
        if (!updatedFormData[1]) {
          updatedFormData[1] = {};
        }
        for (let obj in singleInwardData.jism_jsonb_front) {
          singleInwardData[obj] = singleInwardData.jism_jsonb_front[obj];
        }
        section.rows.forEach((row) => {
          row.forEach((columnName) => {
            const fieldName = `${columnName.name}_${i}`;
            const value =
              columnName.name === "sample_id"
                ? singleInwardData["smpl_detail_smpl_id"]
                : useFor == "sampleInward"
                ? singleInwardData[columnName.subname]
                : singleInwardData[columnName.name];

            if (columnName.name === "smpl_detail_smpl_qty") {
              var spValue = value ? value.split(" ") : [];
              console.log(" spValue", spValue);
              if (spValue.length > 0) {
                updatedFormData[1][fieldName] = spValue[0];
                updatedFormData[1][`${columnName.name}_unit_${i}`] =
                  spValue.length > 1 ? spValue[1] : "";
              }
            } else {
              if (columnName.name === "smpl_detail_dos") {
                updatedFormData[1][fieldName] = formData[0]["smpl_detail_dos"];
              } else if (columnName.name === "jism_is_composite") {
                console.log("valuevaluevaluevaluevalue", value);
                updatedFormData[1][fieldName] = value ? "Composite" : "Lot";
              } else if (columnName.name === "jism_composite_lot_nos") {
                updatedFormData[1][fieldName] = value.join(", ");
              } else if (columnName.name === "smpl_detail_recpt_mode") {
                updatedFormData[1][fieldName] =
                  formData[0]["smpl_detail_recpt_mode"];
              } else {
                updatedFormData[1][fieldName] = value;
              }
            }
          });
        });
        updatedFormData[1]["fk_ji_sample_marks_id_" + i] =
          singleInwardData["jism_id"];
        i++;
        return true;
      });
      if (setIsDisplayNewAddOption) {
        if (formData[0].ji_totalqty <= quantityCount) {
          setIsDisplayNewAddOption(false);
        } else {
          setIsDisplayNewAddOption(true);
        }
      }
      console.log("updatedFormData", updatedFormData);
      setFormData(updatedFormData);
      setTableData(actualResponseData);
    }
  } catch (error) {
    console.error(error);
  }
};

export const getAllSampleAssignmentist = async (
  ji_id,
  OperationTypeID,
  setTableData,
  formData,
  setFormData,
  section,
  setFinalParamDataSort,
  setSampleDataTable,
  setSelectedOptions,
  isForDropdown,
  setOperationAssignmentData,
  useFor = "",
  setIsOverlayLoader,
  isLabWiseFilter,
  labId,
  setIsDisplayNewAddOption
) => {
  try {
    if (setIsOverlayLoader) {
      setIsOverlayLoader(true);
    }
    const bodyData = {
      ji_id: ji_id,
      jis_id: OperationTypeID,
    };
    let res;
    if (isLabWiseFilter) {
      bodyData.lab_id = labId;
      res = await postDataFromApi(getLabWiseSetAssignmentData, bodyData);
    } else {
      console.log("useForuseFor", useFor);
      if (
        [
          "jrf",
          "sampleinward",
          "inwardChecklist",
          "LMSAssignment",
          "GroupAssignment",
        ].includes(useFor)
      ) {
        bodyData.jrf_id = formData[0]?.jrf_id;
      }
      res = await postDataFromApi(TMLAnalysisDataListApi, bodyData);
    }

    if (res?.data?.status === 200 && res.data.data) {
      const responseData = res.data.data;
      if (isForDropdown) {
        setOperationAssignmentData(responseData);
      }
      const newArray = responseData;
      let FinalCombinedArray = [];
      let selectedSimpleIds = [];
      let RFTPIFormData = formData;
      const newExistData = newArray.filter((singleParamaSet, index) => {
        if (
          [
            "jrf",
            "sampleinward",
            "inwardChecklist",
            "LMSAssignment",
            "GroupAssignment",
          ].includes(useFor)
        ) {
          if (!singleParamaSet.jila_for_jrf) {
            return false;
          }
        }
        const samplmarkids = [];
        singleParamaSet.jila_set_markjson.map((simpleId) => {
          if (useFor === "LMSAssignment") {
            const filterdata = formData[0]?.sample_detail_data.filter(
              (singledata) => {
                return singledata.smpl_detail_sample_mark === simpleId;
              }
            );
            if (filterdata.length > 0) {
              selectedSimpleIds.push(filterdata[0].smpl_detail_smpl_id);
              samplmarkids.push(filterdata[0].smpl_detail_smpl_id);
            }
          } else {
            selectedSimpleIds.push(simpleId);
          }
        });
        if (useFor === "LMSAssignment") {
          newArray[index].smpl_set_smpljson = samplmarkids;
          newArray[index].smpl_set_groupjson =
            singleParamaSet.jila_set_groupjson;
          newArray[index].smpl_set_paramjson =
            singleParamaSet.jila_set_paramjson;
          delete newArray[index].jila_set_paramjson;
          delete newArray[index].jila_set_groupjson;
          delete newArray[index].jila_set_markjson;
          let combinedArray = [];
          for (const [key, value] of Object.entries(
            singleParamaSet.smpl_set_groupjson
          )) {
            value.param_type = "Group";
            combinedArray.push({ ...value });
          }
          for (const [key, value] of Object.entries(
            singleParamaSet.smpl_set_paramjson
          )) {
            value.param_type = "Parameter";
            combinedArray.push({ ...value });
          }
          combinedArray = combinedArray.sort(
            (a, b) => a.sequanceNo - b.sequanceNo
          );
          FinalCombinedArray.push(combinedArray);
        } else {
          if (useFor === "OperationDetailsAssignment") {
            if (!RFTPIFormData[0]) {
              RFTPIFormData[0] = {};
            }
            RFTPIFormData[0]["is_set_for_JRF_" + singleParamaSet.jila_id] =
              formData[0]["is_set_for_JRF_" + singleParamaSet.jila_id]
                ? formData[0]["is_set_for_JRF_" + singleParamaSet.jila_id]
                : ["Yes"];
          }
          let combinedArray = [];
          for (const [key, value] of Object.entries(
            singleParamaSet.jila_set_groupjson
          )) {
            value.param_type = "Group";
            combinedArray.push({ ...value });
          }
          for (const [key, value] of Object.entries(
            singleParamaSet.jila_set_paramjson
          )) {
            value.param_type = "Parameter";
            combinedArray.push({ ...value });
          }
          combinedArray = combinedArray.sort(
            (a, b) => a.sequanceNo - b.sequanceNo
          );
          FinalCombinedArray.push(combinedArray);
        }
        return true;
      });
      if (useFor === "OperationDetailsAssignment") {
        setFormData(RFTPIFormData);
      }
      if (setSelectedOptions) {
        setSelectedOptions(selectedSimpleIds);
      }
      setFinalParamDataSort(FinalCombinedArray);
      if (setSampleDataTable) {
        setSampleDataTable(newExistData);
      }
      setTableData(newExistData);
    }
  } catch (error) {
    console.error(error);
  } finally {
    if (setIsOverlayLoader) {
      setIsOverlayLoader(false);
    }
  }
};

export const createTMLAnalysisPageHandleAction = async (
  actionSelected,
  tableData,
  setSaveClicked,
  setEditableIndex,
  popupIndex,
  setIsOverlayLoader,
  setTableData,
  formData,
  setFormData,
  section,
  setFinalParamDataSort,
  setSampleDataTable,
  setSelectedOptions,
  OperationTypeID
) => {
  if (actionSelected == "Delete") {
    setSaveClicked(true);
    setIsOverlayLoader(true);
    let payload = {
      jila_id: tableData[popupIndex]?.jila_id,
    };
    let res = await deleteDataFromApi(deleteTMLAnalysisDeleteApi, payload);
    if (res.data.status === 200) {
      getAllSampleAssignmentist(
        formData[0]?.ji_id,
        OperationTypeID,
        setTableData,
        formData,
        setFormData,
        section,
        setFinalParamDataSort,
        setSampleDataTable,
        setSelectedOptions
      );
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setSaveClicked(false);
    setIsOverlayLoader(false);
  } else if (actionSelected === "Cancel") {
    setEditableIndex("");
  }
};

export const Operation_HH_CreateDataFunction = async (
  formData,
  setIsOverlayLoader,
  setIsPopupOpen,
  OperationType,
  OperationTypeID,
  navigate,
  subTableData
) => {
  // console.log('subTableData',subTableData)
  // return
  let res;
  setIsOverlayLoader(true);
  let ops_vessel_hh = {
    fk_jiid: formData[0].ji_id,
    fk_jisid: OperationTypeID,
    opsvhh_data: subTableData,
  };

  if (formData[1].opsvhh_id) {
    let MainData = {
      opsvhh_id: formData[1].opsvhh_id,
      ops_vessel_hh: ops_vessel_hh,
    };
    res = await putDataFromApi(updateHHApi, MainData);
  } else {
    let MainData = {
      ops_vessel_hh: ops_vessel_hh,
    };
    res = await postDataFromApi(createHHApi, MainData);
  }
  if (res?.data?.status === 200) {
    toast.success(res?.data?.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setIsOverlayLoader(false);
    setIsPopupOpen(false);
    OperationCreateDataFunction(
      formData,
      setIsOverlayLoader,
      setIsPopupOpen,
      OperationType,
      OperationTypeID,
      navigate,
      "in-process"
    );
  } else {
    toast.error(res.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  setIsPopupOpen(false);
  setIsOverlayLoader(false);
};
export const totalTannange = (subTableData) => {
  let totalCount = 0;
  subTableData.map((singleData) => {
    totalCount = totalCount + parseInt(singleData["total_tonnage"]);
  });
  return totalCount;
};
export const Operation_Supervision_CreateDataFunction = async (
  formData,
  setIsOverlayLoader,
  setIsPopupOpen,
  OperationType,
  OperationTypeID,
  navigate,
  subTableData
) => {
  // console.log('subTableData',formData)
  // return
  let res;
  setIsOverlayLoader(true);
  let MainData = {
    ops_vessel_sv: {
      fk_jiid: formData[0].ji_id,
      fk_jisid: OperationTypeID,
      opsvsv_stowageplan: subTableData[0] ? subTableData[0] : [],
      opsvsv_vesselinfo: [
        {
          vessel_arrived: formData[1].vessel_arrived,
          vessel_birthed: formData[1].vessel_birthed,
          initial_draught_survey: formData[1].initial_draught_survey,
        },
      ],
      opsvsv_dischargedetails: subTableData[1] ? subTableData[1] : [],
      opsvsv_otherdetails: [
        {
          colour: formData[1].colour,
          weather: formData[1].weather,
          reciever_surveyor: formData[1].reciever_surveyor,
          agent: formData[1].agent,
          port_surveyor: formData[1].port_surveyor,
          stevedores: formData[1].stevedores,
          cargo_being_discharged_by: formData[1].cargo_being_discharged_by,
          no_of_cranes_being_used: formData[1].no_of_cranes_being_used,
          sampling_done_at: formData[1].sampling_done_at,
          condition_cargo: formData[1].condition_cargo,
          direct_sale_or_stock_sale: formData[1].direct_sale_or_stock_sale,
          last_cargo: formData[1].last_cargo,
          remarks: formData[1].remarks,
        },
      ],
      opsvsv_storagedetail: [
        {
          trans_by: formData[1].trans_by,
          number: formData[1].number,
          stock_yard: formData[1].stock_yard,
          diastance: formData[1].diastance,
          contamination_if_any: formData[1].contamination_if_any,
          adj_cargo_at_stock_yard: formData[1].adj_cargo_at_stock_yard,
        },
      ],
      opsvsv_dischargedetailstotal: totalTannange(subTableData),
      opsvsv_dischargedetailsbalancedqty: "",
      opsvsv_remarks: "",
    },
  };
  res = await postDataFromApi(createSVApi, MainData);
  if (formData[1].opsvsv_id) {
    MainData.opsvsv_id = formData[1].opsvsv_id;
    res = await putDataFromApi(updateSVApi, MainData);
  } else {
    res = await postDataFromApi(createSVApi, MainData);
  }

  if (res?.data?.status === 200) {
    toast.success(res?.data?.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setIsOverlayLoader(false);
    setIsPopupOpen(false);
    OperationCreateDataFunction(
      formData,
      setIsOverlayLoader,
      setIsPopupOpen,
      OperationType,
      OperationTypeID,
      navigate,
      "in-process"
    );
    navigate(
      `/operation/vessel-ji-list/vessel-list/${encryptDataForURL(
        formData[0].ji_id
      )}`
    );
    return;
  } else {
    toast.error(res.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  setIsPopupOpen(false);
  setIsOverlayLoader(false);
};

export const Operation_DraftSurvey_CreateDataFunction = async (
  formData,
  setIsOverlayLoader,
  setIsPopupOpen,
  OperationType,
  OperationTypeID,
  navigate,
  section
) => {
  let opsvd_initial = [];
  let opsvd_interim = [];
  let opsvd_final = [];
  section.headers1.map((header) => {
    opsvd_initial.push({
      [header.name]: formData[1][header.name + "-initial"] || "",
    });
  });
  section.headers1.map((header) => {
    opsvd_interim.push({
      [header.name]: formData[1][header.name + "-interim"] || "",
    });
  });
  section.headers1.map((header) => {
    opsvd_final.push({
      [header.name]: formData[1][header.name + "-final"] || "",
    });
  });
  section.headers2.map((header) => {
    opsvd_initial.push({
      [header.name]: formData[1][header.name + "-initial"] || "",
    });
  });
  section.headers2.map((header) => {
    opsvd_interim.push({
      [header.name]: formData[1][header.name + "-interim"] || "",
    });
  });
  section.headers2.map((header) => {
    opsvd_final.push({
      [header.name]: formData[1][header.name + "-final"] || "",
    });
  });
  let res;
  setIsOverlayLoader(true);
  let MainData = {
    ops_vessel_ds: {
      fk_jiid: formData[0].ji_id,
      fk_jisid: OperationTypeID,
      opsvd_initial: opsvd_initial,
      opsvd_interim: opsvd_interim,
      opsvd_final: opsvd_final,
      opsvd_totalinitial: "",
      opsvd_totalfirstinitial: "",
      opsvd_totallastinitial: "",
      opsvd_changeonshipaccount: "",
      opsvd_qtytobedischarged: "",
      opsvd_diffdisplacement: "",
      opsvd_qtydischargedorloaded: "",
      opsvd_roundoffqty: "",
      opsvd_remarks: formData[0].opsvd_remarks,
    },
  };

  if (formData[1].opsvd_id) {
    MainData.opsvd_id = formData[1].opsvd_id;
    res = await putDataFromApi(updateDSApi, MainData);
  } else {
    res = await postDataFromApi(createDSApi, MainData);
  }
  if (res?.data?.status === 200) {
    toast.success(res?.data?.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setIsOverlayLoader(false);
    setIsPopupOpen(false);
    OperationCreateDataFunction(
      formData,
      setIsOverlayLoader,
      setIsPopupOpen,
      OperationType,
      OperationTypeID,
      navigate,
      "in-process"
    );
    navigate(
      `/operation/vessel-ji-list/vessel-list/${encryptDataForURL(
        formData[0].ji_id
      )}`
    );
    return;
  } else {
    toast.error(res.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  setIsPopupOpen(false);
  setIsOverlayLoader(false);
};
export const jobInstructionUpdateDataFunction = async (
  formData,
  setIsOverlayLoader,
  JRFTPIFormData,
  jrfCreationType
) => {
  let JRFData = [];
  let TPIData = [];
  for (let obj in JRFTPIFormData[0]) {
    let name = obj.split("_");
    const id = name[name.length - 1];
    console.log("JRFTPIFormData", JRFTPIFormData[0]);
    if (JRFTPIFormData[0][obj] === "Yes") {
      JRFData.push(id);
    } else {
      TPIData.push(id);
    }
  }
  let payloadData = {
    ji_id: formData[0].ji_id,
    job_inst_data: {
      status: "posted",
    },
  };
  if (jrfCreationType !== "postOther") {
    payloadData["for_jrf"] = {
      jila_id: JRFData,
    };
  } else {
    payloadData["for_tpi"] = {
      jila_id: TPIData,
    };
  }
  let res;
  setIsOverlayLoader(true);
  let MainData = payloadData;
  res = await putDataFromApi(jobinstructionUpdateApi, MainData);
  if (res?.data?.status === 200) {
    toast.success(res?.data?.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setIsOverlayLoader(false);
  } else {
    toast.error(res.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  setIsOverlayLoader(false);
};

export const getLotCompositeData = async (
  setMasterResponse,
  indexNo,
  formData,
  OperationTypeID
) => {
  console.log("formData", formData);
  try {
    let res = await postDataFromApi(getlotnodropdownApi, {
      ji_id: formData[1]?.ji_id,
      jis_id: OperationTypeID,
    });
    if (res?.data?.status === 200 && res.data.data) {
      const clientData = res.data.data.lot_ids.map((value) => ({
        id: value,
        name: value,
      }));
      const bodyToPass = {
        model: "jism_composite_lot_nos_" + indexNo,
        data: clientData,
      };
      setMasterResponse((prev) => [...prev, bodyToPass]);
    }
  } catch (error) {
    console.error(error);
  }
};

export const getAssignemtnLabDropdownData = async (
  formData,
  OperationTypeID,
  setCustomFormData,
  setDropDownOptionLoaded,
  isForLab,
  labId,
  setOperationAssignmentData,
  isuseforstep5
) => {
  try {
    const payLoad = {
      ji_id: formData[1]?.ji_id,
      jis_id: OperationTypeID,
      lab_id: labId,
    };
    let res;
    if (isuseforstep5) {
      res = await postDataFromApi(getLastStepAssignmentData, payLoad);
    } else {
      res = await postDataFromApi(getAssignemtnLabDropdownApi, payLoad);
    }
    if (res?.data?.status === 200 && res.data.data) {
      if (isForLab) {
        let paramDataGroup = [];
        let paramDataParamater = [];
        res.data.data.map((singleData) => {
          singleData.ops_exec_la_set_paramjson.map((param) => {
            paramDataParamater.push({
              basis: param.basis,
              std_id: param.std_id,
              param_id: param.param_id,
              std_name: param.std_name,
              param_name: param.param_name,
              param_unit: param.param_unit,
            });
          });
          singleData.ops_exec_la_set_groupjson.map((param) => {
            paramDataGroup.push({
              group_id: param.group_id,
              group_name: param.group_name,
              parameters: param.parameters,
            });
          });
        });
        console.log("paramDataParamater", paramDataParamater, paramDataGroup);
        setOperationAssignmentData([
          {
            jia_set_paramjson: paramDataParamater,
            jia_set_groupjson: paramDataGroup,
          },
        ]);
      } else {
        const clientData = res.data.data.map((value) => ({
          id: value.lab_id,
          name: value.lab_name,
        }));
        setCustomFormData((prevData) => {
          return {
            ...prevData,
            [1]: {
              ...prevData[1],
              ["smpl_filter_lab"]:
                clientData.length > 0 ? clientData[0].id : "",
              ["smpl_filter_lot_composite"]: "Lot",
            },
          };
        });
        setDropDownOptionLoaded(clientData);
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const OperationSizeAnalysisCreateDataFunction = async (
  formData,
  setIsOverlayLoader,
  setIsPopupOpen,
  OperationType,
  OperationTypeID,
  navigate,
  subTableData,
  operationStepNo
) => {
  let res;
  setIsOverlayLoader(true);
  let MainData = {
    ops_size_analysis: {
      fk_jiid: formData[0].ji_id,
      fk_jisid: OperationTypeID,
      ops_sizeanalysis_json: subTableData,
    },
  };
  if (formData[1]?.ops_sizeanalysis_id) {
    MainData.ops_sizeanalysis_id = formData[1]?.ops_sizeanalysis_id;
    res = await putDataFromApi(opsSizeAnalysisUpdateApi, MainData);
  } else {
    res = await postDataFromApi(opsSizeAnalysisCreateApi, MainData);
  }
  if (res?.data?.status === 200) {
    toast.success(res?.data?.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setIsOverlayLoader(false);
    setIsPopupOpen(false);
    OperationCreateDataFunction(
      formData,
      setIsOverlayLoader,
      setIsPopupOpen,
      OperationType,
      OperationTypeID,
      navigate,
      "in-process",
      1,
      null,
      operationStepNo
    );
  } else {
    toast.error(res.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  setIsPopupOpen(false);
  setIsOverlayLoader(false);
};

export const getSingleSizeAnalysisData = async (
  OperationTypeID,
  formData,
  setTableData,
  setIsOverlayLoader,
  setFormData
) => {
  try {
    let res = await postDataFromApi(opsSizeAnalysisGetApi, {
      ji_id: formData[1]?.ji_id,
      jis_id: OperationTypeID,
    });
    if (res?.data?.status === 200 && res.data.data) {
      const updatedFormData = { ...formData };
      if (!updatedFormData[1]) {
        updatedFormData[1] = {};
      }
      updatedFormData[1]["ops_sizeanalysis_id"] =
        res.data.data.ops_sizeanalysis_id;
      res.data.data.ops_sizeanalysis_json.map((singleValue, index) => {
        updatedFormData[1]["qty_" + index] = singleValue.qty;
        updatedFormData[1]["size_analysis_" + index] =
          singleValue.size_analysis;
        updatedFormData[1]["unit_" + index] = singleValue.unit;
      });
      setFormData(updatedFormData);
      setTableData(res.data.data.ops_sizeanalysis_json);
    }
  } catch (error) {
    console.error(error);
  }
};

export const getSingleHHData = async (
  OperationTypeID,
  formData,
  setTableData,
  setIsOverlayLoader,
  setFormData,
  section
) => {
  console.log("section----", section);
  try {
    let res = await postDataFromApi(getHHApi, {
      ji_id: formData[1]?.ji_id,
      jis_id: OperationTypeID,
    });
    if (res?.data?.status === 200 && res.data.data) {
      const updatedFormData = { ...formData };
      if (!updatedFormData[1]) {
        updatedFormData[1] = {};
      }
      updatedFormData[1]["ops_sizeanalysis_id"] =
        res.data.data.ops_sizeanalysis_id;
      res.data.data.opsvhh_data.map((singleValue, index) => {
        section.headers.map((singleField) => {
          const fieldName = singleField.name;
          updatedFormData[1][fieldName + "_" + index] = singleValue[fieldName];
        });
      });
      updatedFormData[1]["opsvhh_id"] = res.data.data.opsvhh_id;
      setFormData(updatedFormData);
      setTableData(res.data.data.opsvhh_data);
    }
  } catch (error) {
    console.error(error);
  }
};
export const getSingleDraftSurveyData = async (
  OperationTypeID,
  formData,
  setTableData,
  setIsOverlayLoader,
  setFormData,
  section
) => {
  console.log("section", section);
  try {
    let res = await postDataFromApi(getDSApi, {
      ji_id: formData[1]?.ji_id,
      jis_id: OperationTypeID,
    });
    if (res?.data?.status === 200 && res.data.data) {
      const updatedFormData = { ...formData };
      if (!updatedFormData[1]) {
        updatedFormData[1] = {};
      }
      section.headers1.map((field) => {
        let filterdata = res.data.data.opsvd_initial.filter((item) => {
          return item.hasOwnProperty(field.name);
        });
        if (filterdata.length > 0) {
          updatedFormData[1][field.name + "-initial"] =
            filterdata[0]?.[field.name] || "";
        }
        let filterdata2 = res.data.data.opsvd_interim.filter((item) => {
          return item.hasOwnProperty(field.name);
        });
        if (filterdata2.length > 0) {
          updatedFormData[1][field.name + "-interim"] =
            filterdata2[0]?.[field.name] || "";
        }
        let filterdata3 = res.data.data.opsvd_final.filter((item) => {
          return item.hasOwnProperty(field.name);
        });
        if (filterdata3.length > 0) {
          updatedFormData[1][field.name + "-final"] =
            filterdata3[0]?.[field.name] || "";
        }
      });
      section.headers2.map((field) => {
        let filterdata = res.data.data.opsvd_initial.filter((item) => {
          return item.hasOwnProperty(field.name);
        });
        if (filterdata.length > 0) {
          updatedFormData[1][field.name + "-initial"] =
            filterdata[0]?.[field.name] || "";
        }
        let filterdata2 = res.data.data.opsvd_interim.filter((item) => {
          return item.hasOwnProperty(field.name);
        });
        if (filterdata2.length > 0) {
          updatedFormData[1][field.name + "-initial"] =
            filterdata2[0]?.[field.name] || "";
        }
        let filterdata3 = res.data.data.opsvd_final.filter((item) => {
          return item.hasOwnProperty(field.name);
        });
        if (filterdata3.length > 0) {
          updatedFormData[1][field.name + "-final"] =
            filterdata3[0]?.[field.name] || "";
        }
      });
      // console.log("updatedFormData", updatedFormData);
      // res.data.data.opsvd_initial.map((singleValue, index) => {
      //   singleValue = singleValue[0];
      //   section.headers1.map((field) => {
      //     updatedFormData[1][field.name + "-initial"] =
      //       singleValue?.[field.name] || "";
      //   });
      //   section.headers2.map((field) => {
      //     updatedFormData[1][field.name + "-initial"] =
      //       singleValue?.[field.name] || "";
      //   });
      // });
      // res.data.data.opsvd_interim.map((singleValue, index) => {
      //   section.headers1.map((field) => {
      //     updatedFormData[1][field.name + "-interim"] =
      //       singleValue?.[field.name] || "";
      //   });
      //   section.headers2.map((field) => {
      //     updatedFormData[1][field.name + "-interim"] =
      //       singleValue?.[field.name] || "";
      //   });
      // });
      // res.data.data.opsvd_final.map((singleValue, index) => {
      //   section.headers1.map((field) => {
      //     updatedFormData[1][field.name + "-final"] =
      //       singleValue?.[field.name] || "";
      //   });
      //   section.headers2.map((field) => {
      //     updatedFormData[1][field.name + "-final"] =
      //       singleValue?.[field.name] || "";
      //   });
      // });
      updatedFormData[1]["opsvd_id"] = res.data.data.opsvd_id;

      setFormData(updatedFormData);
    }
  } catch (error) {
    console.error(error);
  }
};

export const getSingleSupervissionData = async (
  OperationTypeID,
  formData,
  setTableData,
  setIsOverlayLoader,
  setFormData,
  section,
  tableData
) => {
  console.log("section", section, tableData);
  try {
    let res = await postDataFromApi(getSVApi, {
      ji_id: formData[1]?.ji_id,
      jis_id: OperationTypeID,
    });
    if (res?.data?.status === 200 && res.data.data) {
      const updatedFormData = { ...formData };
      if (!updatedFormData[1]) {
        updatedFormData[1] = {};
      }
      res.data.data.opsvsv_stowageplan.map((singleValue, index) => {
        section[0].headers.map((field) => {
          updatedFormData[1][field.name + "_" + index] =
            singleValue[field.name];
        });
      });
      res.data.data.opsvsv_dischargedetails.map((singleValue, index) => {
        section[2].headers.map((field) => {
          updatedFormData[1][field.name + "_" + index] =
            singleValue[field.name];
        });
      });
      res.data.data.opsvsv_vesselinfo.map((singleValue, index) => {
        section[1].fields.map((field) => {
          updatedFormData[1][field.name] = singleValue[field.name];
        });
      });
      res.data.data.opsvsv_otherdetails.map((singleValue, index) => {
        section[3].fields.map((field) => {
          updatedFormData[1][field.name] = singleValue[field.name];
        });
      });
      res.data.data.opsvsv_storagedetail.map((singleValue, index) => {
        section[4].fields.map((field) => {
          updatedFormData[1][field.name] = singleValue[field.name];
        });
      });
      updatedFormData[1]["opsvsv_id"] = res.data.data.opsvsv_id;
      setFormData(updatedFormData);
      let tableData = [];
      tableData.push(res.data.data.opsvsv_stowageplan);
      tableData.push(res.data.data.opsvsv_dischargedetails);
      console.log(
        "tab",
        tableData,
        res.data.data.opsvsv_stowageplan,
        res.data.data.opsvsv_dischargedetails
      );
      setTableData(tableData);
    }
  } catch (error) {
    console.error(error);
  }
};
