import React, { useState } from "react";
import RenderFields from "./RenderFields";
const JIPopupModal = ({
  section,
  sectionIndex,
  formData,
  handleFieldChange,
  formErrors,
  setViewDetail,
  setIsOverlayLoader,
  isOverlayLoader
}) => {
  console.log("Section : ", section);
  return (
    <div className="popupSearchContainerBG">
      <div className="popupInwardModal  JIPopupModal">
        <div className="rejectSearchCross">
          <button
            onClick={() => setViewDetail(false)}
            className="nonNativeButton2"
            aria-label="Reject Button"
          >
            <i className="bi bi-x-lg h4"></i>
          </button>
        </div>
        <div className="JIPopupModalContainer">
          {section.fields?.map((field, fieldIndex) => (
            <div
              className={"col-md-" + field.width}
              key={"Headers-" + fieldIndex}
            >
              <RenderFields
                field={field}
                sectionIndex={sectionIndex}
                fieldIndex={fieldIndex}
                formData={formData}
                handleFieldChange={handleFieldChange}
                formErrors={formErrors}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default JIPopupModal;
