import { toast } from "react-toastify";
import {
  GetTenantDetails,
  postDataFromApi,
  putDataFromApi,
} from "../../../services/commonServices";
import {
  getJRFOperationDataApi,
  referenceDataApi,
} from "../../../services/api";
import { OperationCreateDataFunction } from "./operations/TMLOperations";
export const getReferenceData = async (
  referenceNo,
  setIsValideReferenceNo,
  formData,
  setReferenceData,
  setFormData,
  setSubCommodityOptions,
  setIsRefrenceNoCalled,
  setIsOverlayLoader
) => {
  let bodyToPass = {
    jrf_referenceno: referenceNo,
  };
  setIsValideReferenceNo(false);
  setIsRefrenceNoCalled(false);
  setIsOverlayLoader(true);
  try {
    let res = await postDataFromApi(referenceDataApi, bodyToPass);
    if (res?.data?.status === 200) {
      const response = res.data.data;
      if (formData["0"]) {
        setReferenceData(response);

        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            0: {
              ...prevFormData[0],
              jrf_state: response.state.state_name,
              jrf_company: response.company.cmp_id,
              jrf_company_name: response.company.cmp_name,
              // cmp_address: response.company.cmp_address,
              jrf_commodity: response.commodity.cmd_name,
              jrf_mode: response.mode.mode_name,
            },
          };
        });
        let options = [];
        response.commodity.sub_commodity.map((singleComm, i) =>
          options.push({
            id: singleComm.sub_cmd_id,
            name: singleComm.sub_cmd_name,
          })
        );
        setSubCommodityOptions(options);
      }
      setIsValideReferenceNo(true);
      setIsOverlayLoader(false);
    }
    setIsRefrenceNoCalled(true);
  } catch (error) {
    toast.error(error.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        0: {
          ...prevFormData[0],
          jrf_state: "--",
          jrf_commodity: "--",
          jrf_mode: "--",
        },
      };
    });
    setSubCommodityOptions([]);
    setIsRefrenceNoCalled(true);
    setIsOverlayLoader(false);
  } finally {
    setIsOverlayLoader(false);
  }
};
export const handleJRFCreateOrUpdate = async (
  setSaveClicked,
  formData,
  referenceData,
  jrfId,
  formConfig,
  setJRFID,
  navigate,
  setIsPopupOpen,
  type = "",
  isExternalJRF,
  setIsOverlayLoader
) => {
  setSaveClicked(true);
  let payloadData = formData[0];
  let actualFormData = formData[0];

  payloadData.jrf_commodity = referenceData?.commodity?.cmd_id;
  if (type === "post") {
    payloadData.jrf_status = "posted";
  } else {
    payloadData.jrf_status = "saved";
  }
  if (isExternalJRF) {
    if (type === "post") {
      payloadData.jrf_status = "awaited";
    }
    payloadData.jrf_is_external = 1;
  }
  payloadData.jrf_remark = null;
  payloadData.tenant = GetTenantDetails(1);
  delete payloadData.jrf_id;
  let res;
  setIsOverlayLoader(true);
  if (jrfId) {
    let MainData = {
      jrf_data: payloadData,
    };
    MainData.jrf_id = jrfId;
    res = await putDataFromApi(formConfig.apiEndpoints.update, MainData);
  } else {
    let MainData = {
      jrf: payloadData,
    };
    res = await postDataFromApi(formConfig.apiEndpoints.create, MainData);
  }

  payloadData.jrf_commodity = actualFormData?.cmd_id;
  delete payloadData.jrf_status;
  if (res?.data?.status === 200) {
    toast.success(res?.data?.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setJRFID(res?.data?.data?.jrf_id);
    setIsOverlayLoader(false);
    setTimeout(() => {
      navigate("/jrfListing");
    }, 1000);
  } else {
    toast.error(res.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  setSaveClicked(false);
  setIsPopupOpen(false);
  setIsOverlayLoader(false);
};

export const handleFormSave = async (
  e,
  handleSubmit,
  setIsPopupOpen,
  setJRFCreationType
) => {
  e.preventDefault();
  let isValidate = handleSubmit();
  if (!isValidate) {
    return false;
  }
  setIsPopupOpen(true);
  setJRFCreationType("save");
};
export const handleFormPost = async (
  e,
  handleSubmit,
  setJRFCreationType,
  setIsPopupOpen
) => {
  e.preventDefault();
  let isValidate = handleSubmit();
  if (!isValidate) {
    return false;
  }
  setJRFCreationType("post");
  setIsPopupOpen(true);
};

export const handleJRFCreateOrUpdateWithOperations = async (
  formData,
  setIsOverlayLoader,
  setIsPopupOpen,
  navigate,
  jrfCreateUrl,
  OperationTypeID,
  OperationType,
  fk_ops_lms_assignments
) => {
  let payloadData = {
    // "jrf_contact_person": formData[0]?.client_details?.client_name,
    jrf_contact_person_number: "-",
    cmp_address: formData[0]?.company?.company_address,
    noOfSamples: 0,
    jrf_date: formData[0]?.ji_date,
    jrf_referenceno: formData[0]?.ji_reference_number,
    jrf_state: "-",
    jrf_company: formData[0]?.fk_companyid,
    jrf_company_name: formData[0]?.company?.company_name,
    jrf_commodity: formData[0]?.fk_commodityid,
    jrf_mode: formData[0]?.mode,
    fk_sub_commodity: formData[0]?.fk_subcommodityid,
    jrf_quanity_sample: "-",
    jrf_desc_of_sample: "-",
    jrf_parameters_to_analyze: "-",
    jrf_other_info: "-",
    jrf_test_repo_req_on: 1,
    jrf_test_method: formData[0]?.ji_sampling_methods,
    jrf_terms_and_conditions: true,
    jrf_lab: formData[1]?.smpl_filter_lab,
    jrf_status: "saved",
    jrf_remark: null,
    jrf_is_ops: true,
    fk_jiid: formData[0]?.ji_id,
    fk_jisid: OperationTypeID,
    tenant: GetTenantDetails(1),
    fk_ops_lms_assignments: fk_ops_lms_assignments,
  };
  let res;
  setIsOverlayLoader(true);
  let MainData = {
    jrf: payloadData,
  };
  res = await postDataFromApi(jrfCreateUrl, MainData);
  if (res?.data?.status === 200) {
    toast.success(res?.data?.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // setTimeout(() => {
    //   navigate("/operation/vessel-ji-list");
    // }, 1000);
  } else {
    toast.error(res.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  setIsPopupOpen(false);
  setIsOverlayLoader(false);
};
export const getJRFOperationData = async (ji_id, jis_id) => {
  try {
    let tempBody = {
      ji_id: ji_id,
      jis_id: jis_id,
    };
    let res = await postDataFromApi(getJRFOperationDataApi, tempBody);
  } catch (error) {
    console.error(error);
  }
};
