import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

import formConfig from "../../formJsonData/Operations/jobinstructions/JrfInstruction.json";
import Form from "../../components/common/Form";
import { getDataFromApi, postDataFromApi } from "../../services/commonServices";
import { getClientListDataApi, getPlaceDataApi, getSubplierDataApi, MasterListApi } from "../../services/api";
import { getOpeartionType } from "../../components/common/commonHandlerFunction/jobinstructionHandlerFunctions";

const JrfInstruction = () => {
  const [masterResponse, setMasterResponse] = useState([]);

  useEffect(() => {
    // getClientData();
    getCompanyData();
    // getCommodityData();
    getPlaceofWorkData();
    // getSuplierData();
    getOpeartionType(setMasterResponse)
  }, []);
  const getClientData = async () => {
    try {
      let res = await getDataFromApi(getClientListDataApi + "?is_dropdown=" + true);
      if (res?.data?.status === 200 && res.data.data) {
        const clientData = res.data.data.map((client) => ({
          id: client.cust_id,
          name: client.cust_name,
        }));

        const bodyToPass = {
          model: "fk_clientid",
          data: clientData,
        };
        setMasterResponse((prev) => [...prev, bodyToPass]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCommodityData = async () => {
    try {
      let res = await postDataFromApi(MasterListApi, { is_dropdown: true, model_name: "commodity" });
      if (res?.data?.status === 200 && res.data.data) {
        const clientData = res.data.data.map((client) => ({
          id: client[0],
          name: client[1],
        }));

        const bodyToPass = {
          model: "fk_commodityid",
          data: clientData,
        };
        setMasterResponse((prev) => [...prev, bodyToPass]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getCompanyData = async () => {
    try {
      let res = await postDataFromApi(MasterListApi, { is_dropdown: true, model_name: "company" });
      if (res?.data?.status === 200 && res.data.data) {
        const clientData = []
        res.data.data.map((client) => {
          if (client[1] !== "TCRC PETROLABS BHARAT PRIVATE LIMITED") {
            clientData.push({
              id: client[0],
              name: client[1],
            })
          }
        });

        const bodyToPass = {
          model: "fk_companyid",
          data: clientData,
        };
        setMasterResponse((prev) => [...prev, bodyToPass]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getPlaceofWorkData = async () => {
    try {
      let res = await getDataFromApi(getPlaceDataApi + "?is_dropdown=" + true);
      if (res?.data?.status === 200 && res.data.data) {
        const clientData = res.data.data.map((client) => ({
          id: client.pow_id,
          name: client.pow_name,
        }));

        const bodyToPass = {
          model: "fk_placeworkid",
          data: clientData,
        };
        setMasterResponse((prev) => [...prev, bodyToPass]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getSuplierData = async () => {
    try {
      let res = await getDataFromApi(getSubplierDataApi + "?is_dropdown=" + true);
      if (res?.data?.status === 200 && res.data.data) {
        const clientData = res.data.data.map((client) => ({
          id: client.supl_id,
          name: client.supl_name,
        }));

        const bodyToPass = {
          model: "fk_supplierid",
          data: clientData,
        };
        setMasterResponse((prev) => [...prev, bodyToPass]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const tileHeader = [{ Text: "Company & Commodity", leftSubTitle: "Step 1", rightSubTitle: "In Progress" },
  { Text: "Scope of Work", leftSubTitle: "Step 2", rightSubTitle: "In Progress" },
  { Text: "Groups & Parameters", leftSubTitle: "Step 3", rightSubTitle: "In Progress" },
  { Text: "Nominations Details", leftSubTitle: "Step 4", rightSubTitle: "In Progress" }
  ]

  return (
    <Row className="rowWidthAdjust">
      <Col>
        <Form formConfig={formConfig} masterResponse={masterResponse} setMasterResponse={setMasterResponse} tileHeader={tileHeader}/>
      </Col>
    </Row>
  );
};

export default JrfInstruction;
