import React, { useEffect, useState } from "react";
import RenderFields from "./RenderFields";
import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";

import ActionOptionsTable from "./ActionOptionsTable";
import { getSelectedOptionName, getTotalValues } from "../../services/commonFunction";
import PropTypes from "prop-types";
import SampleVerificationDetals from "./commonModalForms/SampleVerificationDetals";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
const RenderTableForDraftSurveySection = ({
  section,
  sectionIndex,
  handleFieldChange,
  formData,
  setFormData,
  formErrors
}) => {
  const [addInterm, setAddInterm] = useState(false)

  const getSingleCommonfield = (name, type, label = "") => {
    return (<RenderFields
      field={{
        width: 4,
        name: name,
        label: label,
        type: type,
        options: [],
        required: true,
        fieldWidth: 75,
        multiple: true,
      }}
      sectionIndex={sectionIndex}
      fieldIndex={1 * 100 + 1}
      formData={formData}
      handleFieldChange={handleFieldChange}
      formErrors={formErrors}
      renderTable={true}
      tableIndex={sectionIndex}
    />)
  }

  return (
    <div key={sectionIndex} className="row my-2 mx-0 bg-white">

      <Card className="Scrollable">
        <CardBody>
          <CardTitle tag="h5">{section.title}</CardTitle>
          <CardSubtitle className="mb-2 text-muted draft_table_subtitle" tag="h6">
            Quantity On Board On Ship's Account
          </CardSubtitle>
          <table className={"table table-white responsive borderless no-wrap mt-3 align-middle renderTable draftSurveyRenderTable " + (addInterm && "draftSurveyRenderTable_final")}>
            <thead>
              <tr>
                <th>Title</th>
                <th>Initial</th>
                {
                  addInterm && (<>
                    <th>Interim</th>
                    <th>Final</th>
                  </>)
                }

                <th>Unit</th>
              </tr>
            </thead>
            <tbody>
              {
                section.headers1.map((header) => {
                  return (<tr>
                    <td>{header.label}</td>
                    <td>{getSingleCommonfield(header.name + "-initial", "text")}</td>
                    {
                      addInterm && (<>
                        <td>{getSingleCommonfield(header.name + "-interim", "text")}</td>
                        <td>{getSingleCommonfield(header.name + "-final", "text")}</td>
                      </>)
                    }

                    <td>{getSingleCommonfield(header.name + "-unit", "text")}</td>
                  </tr>)
                })
              }
              <tr>
                <td>Total</td>
                <td>{getTotalValues(formData, 'initial', section.headers1)}</td>
                {
                  addInterm && (
                    <>
                      <td>{getTotalValues(formData, 'interim', section.headers1)}</td>
                      <td>{getTotalValues(formData, 'final', section.headers1)}</td>
                    </>
                  )
                }
                <td>MTS</td>
              </tr>
            </tbody>
          </table>
          <div className="draftSurveyRenderTable_btns_container">
            <table className="draft_bottom_table">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Change on ship's Account</td>
                  <td>{0}</td>
                </tr>
                <tr>
                  <td>Balance Quantity To Be Discharged</td>
                  <td>{0}</td>
                </tr>
              </tbody>
            </table>
            <div className="submit_btns draftSurveyRenderTable_btns">
              {
                !addInterm && <button
                  type="button"
                  className="saveBtn"
                  id="submit_btn3"
                  onClick={() => setAddInterm(true)}
                >
                  Add Interim
                </button>
              }
              {
                addInterm && (<button
                  type="button"
                  className="saveBtn"
                  id="submit_btn3"
                >
                  Add Final
                </button>)
              }
            </div>
          </div>
          <hr />
          <table className={"table table-white responsive borderless no-wrap mt-3 align-middle renderTable draftSurveyRenderTable " + (addInterm && "draftSurveyRenderTable_final")}>
            <tbody>
              {
                section.headers2.map((header) => {
                  return (<tr>
                    <td>{header.label}</td>
                    <td>{getSingleCommonfield(header.name + "-initial", "text")}</td>
                    {
                      addInterm && (
                        <>
                          <td>{getSingleCommonfield(header.name + "-interim", "text")}</td>
                          <td>{getSingleCommonfield(header.name + "-final", "text")}</td>
                        </>
                      )
                    }

                    <td>{getSingleCommonfield(header.name + "-unit", "text")}</td>
                  </tr>)
                })
              }
              <tr>
                <td>Total</td>
                <td>{getTotalValues(formData, 'initial', section.headers2)}</td>
                {
                  addInterm && (
                    <>
                      <td>{getTotalValues(formData, 'interim', section.headers2)}</td>
                      <td>{getTotalValues(formData, 'final', section.headers2)}</td>
                    </>
                  )
                }
                <td>MTS</td>
              </tr>
            </tbody>
          </table>

          <table className="draft_bottom_table_second">
            <thead>
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Difference in Displacement</td>
                <td>{0}</td>
              </tr>
              <tr>
                <td>Change on ship's Account</td>
                <td>{0}</td>
              </tr>
              <tr>
                <td>Quantity Discharged / Loaded</td>
                <td>{0}</td>
              </tr>
              <tr>
                <td>Rounded of Qty.</td>
                <td>{0}</td>
              </tr>
            </tbody>
          </table>

          <div className="draft_survey_remarks">
            {getSingleCommonfield("opsvd_remarks", "text", 'Remarks')}
          </div>
        </CardBody>
      </Card>
    </div >
  );
};

RenderTableForDraftSurveySection.propTypes = {
  section: PropTypes.string,
  sectionIndex: PropTypes.number,
  formData: PropTypes.object,
  handleFieldChange: PropTypes.func,
};


export default RenderTableForDraftSurveySection;
