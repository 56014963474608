import React, { useState, useEffect } from "react";
import { getDataFromApi, postDataFromApi } from "../../../services/commonServices";
import { Dropdown } from "react-bootstrap";
import PropTypes from 'prop-types';

const DropDownWithLoadMore = ({ field }) => {
    let {
        name,
        label,
        value,
        onChange,
        required,
        options,
        fieldWidth,
        masterOptions,
        customname,
        from,
        multiple,
        placeholder,
        actionClicked,
        specialClass,
        isSearchable,
        readOnly,
        model_name,
        apiendpoint,
        apimethod,
        optionData
    } = field;
    const [items, setItems] = useState([]);
    const [nextPage, setNextPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    const [searchQuery, setSearchQuery] = useState("");
    const [defaultValue, setDefaultValue] = useState("");

    useEffect(() => {
        if (searchQuery.length > 2 || searchQuery.length === 0) {
            fetchData(true);
        }
    }, [searchQuery]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);

        setNextPage(1);
        setHasMore(true);
    };

    const fetchData = async (reset = false,forDefaulrshow="") => {
        try {
            let tempBody = {
                model_name: model_name,
                load_more: true,
                is_dropdown: true,
                is_search_dropdowm: true,
            };
            if (forDefaulrshow) {
                tempBody.pre_selected_id = value
            }
            let res;

            let newEndPoint = nextPage > 0
                ? `${apiendpoint}?page=${nextPage}&search=${searchQuery}`
                : `${apiendpoint}?search=${searchQuery}`
            if (apimethod === "GET") {
                newEndPoint = newEndPoint + "&is_dropdown=" + true+"&load_more="+true
                if (forDefaulrshow) {
                    newEndPoint = newEndPoint + "&pre_selected_id=" + value
                }
                res = await getDataFromApi(newEndPoint, tempBody
                );
            }
            else if (apimethod === "POST") {
                res = await postDataFromApi(newEndPoint, tempBody
                );
            }

            if (res.data.status === 200) {
                if (reset) {
                    setItems(res.data.data);
                } else {
                    setItems((prevItems) => [...prevItems, ...res.data.data]);
                }
                setHasMore(res?.data?.links?.next !== null);
                setNextPage((prevPage) => prevPage + 1);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const [newOptions, setNewOptions] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItemName, setSelectedItemName] = useState(value || null);
    const toggleOption = (e, option, optionName) => {
        onChange(option, 1);
    };
    useEffect(() => {
        masterOptions?.map((model, index) => {
            if (model.model === name || model.model === customname) {
                setNewOptions(model.data);
            }
        });
    }, [options, newOptions, name, masterOptions]);

    const [errorMsg, setErrorMsg] = useState(false);
    useEffect(() => {
        if ((value === undefined || value === "") && required) {
            setErrorMsg(true);
        } else {
            checkDefaultSelected()
            setErrorMsg(false);
        }
        if (!defaultValue && value) {
            setDefaultValue(value)
            fetchData(true,1)
        }
    }, [value]);
    useEffect(()=>{
        checkDefaultSelected()
    },[items])
    const checkDefaultSelected=()=>{
        const filterData = items.filter((item) => {
            return item[optionData.id] === value
        })
        
        if (filterData.length > 0) {
            if(name==="fk_placeworkid"){
                console.log('filterData',filterData)
            }
            setSelectedItem(filterData[0][optionData.id])
            setSelectedItemName(filterData[0][optionData.label])
        }
    }
    return (
        <div className="form-group my-2" style={{ position: "relative" }}>
            {from !== "Table" && label && (
                <label htmlFor={name} style={{ width: `${18}%` }}>
                    {label}
                    <span className="required_mark"> {required ? ` *` : null}</span>
                </label>
            )}
            <Dropdown
                className={"w-" + (fieldWidth ?? "75") + " d-inline-block mx-2"}
            >
                <Dropdown.Toggle id="dropdown-basic">
                    {" "}
                    <span className="multipleSelectHeader">
                        {selectedItemName || "Select " + (label || "")}
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="loadmore_dropdown_menu">
                    {isSearchable && <input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearch}
                        className="loadMoreInput"
                    />}
                    <br />
                    <div className="loadMoreOptions">
                        {items.map((item, index) => (
                            <Dropdown.Item
                                value={item[optionData.id]}
                                key={"Dropdown-" + index}
                                onClick={(e) =>
                                    toggleOption(e, item[optionData.id])
                                }
                                active={selectedItem === item[optionData.id]}
                            >
                                {item[optionData.label]}
                            </Dropdown.Item>
                        ))}
                    </div>

                    {hasMore && (
                        <button
                            onClick={() => fetchData(false)}
                            className="load_more_btn"
                            type="button"
                        >
                            Load More...
                        </button>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

DropDownWithLoadMore.propTypes = {
    field: PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
        onChange: PropTypes.func.isRequired,
        required: PropTypes.bool,
        options: PropTypes.array.isRequired,
        error: PropTypes.string,
        fieldWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        multiple: PropTypes.bool,
        placeholder: PropTypes.string,
        masterOptions: PropTypes.array,
        customname: PropTypes.string,
        actionClicked: PropTypes.func,
        specialClass: PropTypes.string,
        from: PropTypes.string,
        viewOnly: PropTypes.bool,
        staticOptions: PropTypes.bool,
    }).isRequired
};

export default DropDownWithLoadMore;
