import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

import formConfig from "../../../../formJsonData/Operations/Vessel/VesseLOperations/TMLAnalysOperation.json";
import Form from "../../../../components/common/Form";
import { sampleMarkOptionsApi, sampleMarkOptionsLotWiseApi } from "../../../../services/api";
import { postDataFromApi } from "../../../../services/commonServices";
import commonFields from "../../../../formJsonData/Operations/commonFields.json";
import { decryptDataForURL } from "../../../../utills/useCryptoUtils";
import { useParams } from "react-router-dom";
formConfig["sections"][0].fields = commonFields;
const TMLAnalysisOperations = () => {
  const [masterResponse, setMasterResponse] = useState([]);
  const [operationStepNo, setOperationStepNo] = useState(0);
  let { TMLType } = useParams();
  TMLType = TMLType ? decryptDataForURL(TMLType) : "";
  useEffect(() => {
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.split("?")[1]);
    let stepNo = params.get("operationStepNo")
      ? params.get("operationStepNo")
      : "";
    stepNo = decryptDataForURL(stepNo);
    setOperationStepNo(stepNo);

    // formConfig['sections'][1]['tabs'][0].tileSubHeader = tileSubHeaderQA;

  });
  const tileSubHeaderPSI = [
    { Text: "Groups & Parameters" },
    { Text: "Sample Preparation" },
    { Text: "Assign Parameters" },
    { Text: "Send to JRF / Oth. TPI" },
  ]
  const tileSubHeaderTML = [
    { Text: "Groups & Parameters" },
    { Text: "Sample Preparation" },
    { Text: "Assign Parameters" },
    { Text: "Send to JRF / Oth. TPI" },
  ]
  const tileSubHeaderHH = [
    { Text: "H&H" }
  ]
  const tileSubHeaderSV = [
    { Text: "Supervision" },
    { Text: "Vessel Information" },
    { Text: "Discharge Details" },
    { Text: "Other Details" },
    { Text: "Storage Details" },
  ]
  const tileSubHeaderDS = [
    // { Text: "Draft Survey" },
    // { Text: "Groups & Parameters" },
  ]
  const tileSubHeaderQA = [
    { Text: "Groups & Parameters" },
    { Text: "Sample Preparation" },
    { Text: "Assign Parameters" },
    { Text: "Size Analysis" },
    { Text: "Send to JRF / Oth. TPI" },
  ]
  // Get the value of OperationType
  const hash = window.location.hash;
  const params = new URLSearchParams(hash.split("?")[1]);
  let operationType = params.get('OperationType');
  console.log("Operation Type : ", operationType);

  if (operationType == "H&H") {
    formConfig['sections'][1]['tabs'][0].tileSubHeader = tileSubHeaderHH;
  }
  else if (operationType == "Supervision") {
    formConfig['sections'][1]['tabs'][0].tileSubHeader = tileSubHeaderSV;

  }
  else if (operationType == "Draft Survey") {
    formConfig['sections'][1]['tabs'][0].tileSubHeader = tileSubHeaderDS;

  }
  else if (operationType === "Pre-Shipment (PSI)") {
    formConfig['sections'][1]['tabs'][0].tileSubHeader = tileSubHeaderPSI;
  }
  else if (operationType === "Transportable moisture limit (TML)") {
    formConfig['sections'][1]['tabs'][0].tileSubHeader = tileSubHeaderTML;
  }
  else if (operationType == "Quality Analysis") {
    formConfig['sections'][1]['tabs'][0].tileSubHeader = tileSubHeaderQA;
  }
  // formConfig['sections'][1]['tabs'][0].tileSubHeader = tileSubHeaderQA;


  const getSampleIdsMasterData = async (ji_id, jis_id, context = "Lot") => {
    try {
      let tempBody = {
        ji_id: ji_id,
        jis_id: jis_id,
        context: context.toLowerCase()
      };
      // let res = await postDataFromApi(sampleMarkOptionsApi, tempBody);
      let res = await postDataFromApi(sampleMarkOptionsLotWiseApi, tempBody);
      if (res.data && res.data.status === 200 && res.data.data) {
        const transformedData = res.data.data.map((value) => ({
          id: value,
          name: value,
        }));
        const bodyToPass = {
          model: "smpl_set_smpljson",
          data: transformedData,
        };
        let isExists = false;
        let filterData = masterResponse.filter((model) => {
          if (model.model === "smpl_set_smpljson") {
            model.data = transformedData;
            isExists = true;
          }
          return true;
        });
        if (isExists) {
          setMasterResponse(filterData);
        } else {
          setMasterResponse((prev) => [...prev, bodyToPass]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Row className="rowWidthAdjust">
      <Col>
        <Form
          formConfig={formConfig}
          masterResponse={masterResponse}
          setMasterResponse={setMasterResponse}
          useForComponent={"OperationDetailsAssignment"}
          getSampleIdsMasterData={getSampleIdsMasterData}
          operationStepNo={operationStepNo}
        />
      </Col>
    </Row>
  );
};

export default TMLAnalysisOperations;
